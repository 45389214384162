/*
 * Entry point of global application style.
 * Component-specific style should not go here and be included directly as part of the components.
 */
// Theme variables, must be included before the libraries to allow overriding defaults
@import "theme/theme-variables";
// 3rd party libraries
@import "node_modules/bootstrap/scss/bootstrap";
@import "@fortawesome/fontawesome-free-webfonts/scss/fontawesome.scss";
@import "@fortawesome/fontawesome-free-webfonts/scss/fa-brands.scss";
@import "@fortawesome/fontawesome-free-webfonts/scss/fa-regular.scss";
@import "@fortawesome/fontawesome-free-webfonts/scss/fa-solid.scss";
// Theme customization
@import "theme/theme";

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                   supported by Chrome, Edge, Opera and Firefox */
}
